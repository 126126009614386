import { branchColumnsDataOrders } from './variables/ColumnDataOrders'
import BranchListTable from './components/List'
import Card from 'components/card'
import apiConfig from 'common/config/apiConfig'
import { useState, useEffect } from 'react'
import ApiCaller from 'common/services/apiServices'

/**
 * Renders the Office List component with the fetched Office data.
 *
 * @return {JSX.Element} The rendered Office List component
 */
const BranchList = () => {
  const [allOfficeData, setBranchData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isBranchLoading, setBranchLoading] = useState(false)
  const apiService = ApiCaller()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setBranchLoading(true)
        const apiUrl = apiConfig.BRANCH_LIST
        const payload = {
          page: currentPage,
          keyword: searchValue,
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          setBranchData(response?.data?.data?.results)
          setTotalCount(response?.data?.data?.total || 0)
        } else {
          setBranchData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setBranchLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setBranchLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <BranchListTable
          tableData={allOfficeData}
          columnsData={branchColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isBranchLoading={isBranchLoading}
          setBranchLoading={setBranchLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default BranchList
