import { zoneColumnsDataOrders } from './variables/ColumnsDataOrders'
import ZoneListTable from './components/List'
import Card from 'components/card'
import { useState, useEffect } from 'react'
import { getZone } from 'common/commonFunction'
/**
 * Renders the Zone List component with the fetched zone data.
 *
 * @return {JSX.Element} The rendered Zone List component
 */
const ZoneList = () => {
  const [allZoneData, setZoneData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isZoneLoading, setZoneLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setZoneLoading(true)
        const payload = {
          page: currentPage,
          keyword: searchValue,
        }
        const getZoneList = await getZone(payload)
        if (getZoneList?.data?.value) {
          setZoneData(getZoneList?.data?.data?.results)
          setTotalCount(getZoneList?.data?.data?.total || 0)
        } else {
          setZoneData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setZoneLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setZoneLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <ZoneListTable
          tableData={allZoneData}
          columnsData={zoneColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isZoneLoading={isZoneLoading}
          setZoneLoading={setZoneLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default ZoneList
