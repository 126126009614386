import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a zone.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddOffice = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      CompanyName: '',
      TypeOfOffice: '',
      OfficeName: '',
      City: '',
      Address: '',
      Phone: '',
      Email: '',
    },
  })

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [companyNameOptions, setCompanyNameOptions] = useState([])
  const [officeTypeOptions, setOfficeTypeOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cityOptions, setCityOptions] = useState([])

  /**
   * Fetches company name options from the API based on user input.
   * The function is triggered when the user types in the company name input field.
   * It sends a request to the server to fetch matching company names.
   *
   * @async
   * @function fetchOptions
   * @param {string} inputValue - The user-typed value for filtering company options. Defaults to an empty string if not provided.
   * @returns {Promise<void>} A promise that resolves when the company name options are fetched and set.
   */
  const fetchOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: 1,
      }
      const apiUrl = apiConfig.COMPANY_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setCompanyNameOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the company name field.
   * Calls the API to fetch company name options when the input value is longer than 2 characters.
   *
   * @function handleInputChange
   * @param {string} inputValue - The current input value from the company name input field.
   */
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the company name field.
   * If no company name is selected, it fetches the initial list of company names.
   *
   * @function handleSelectionChange
   * @param {Object|null} CompanyName - The selected company name object or null if nothing is selected.
   */
  const handleSelectionChange = (CompanyName) => {
    if (!CompanyName) {
      fetchOptions() // Call the function to perform API request
    }
  }

  /**
   * Fetches office type options from the API based on user input.
   * The function sends a request to the server to retrieve matching office types.
   *
   * @async
   * @function fetchOfficeTypeOptions
   * @param {string} [inputValue=''] - The user-typed value used to filter office type options.
   * @returns {Promise<void>} A promise that resolves when the office type options are fetched and set.
   */
  const fetchOfficeTypeOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: 1,
      }
      const apiUrl = apiConfig.TYPE_OF_OFFICE_LIST
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setOfficeTypeOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the office type field.
   * Triggers the API call to fetch office type options if the input value is longer than 2 characters.
   *
   * @function handleOfficeTypeInputChange
   * @param {string} inputValue - The current input value from the office type input field.
   */
  const handleOfficeTypeInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchOfficeTypeOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the office type field.
   * If no office type is selected, it calls the function to fetch a list of options.
   *
   * @function handleOfficeTypeSelectionChange
   * @param {Object|null} TypeOfOffice - The selected office type object or null if no office type is selected.
   */
  const handleOfficeTypeSelectionChange = (TypeOfOffice) => {
    if (!TypeOfOffice) {
      fetchOptions() // Call the function to perform API request
    }
  }

  /**
   * Submits the form data to create or edit an office.
   *
   * This function handles form submission for creating or editing an office.
   * It constructs a payload from the form data, sends it to the server,
   * and provides feedback to the user via notifications.
   * On success, the user is redirected to the office list; otherwise, an error is displayed.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The data submitted from the form.
   * @param {string} formData.OfficeName - The name of the office.
   * @param {Object} formData.CompanyName - The company associated with the office.
   * @param {string} formData.CompanyName.value - The ID of the selected company.
   * @param {Object} formData.TypeOfOffice - The type of office.
   * @param {string} formData.TypeOfOffice.value - The ID of the selected office type.
   * @param {Object} formData.City - The city where the office is located.
   * @param {string} formData.City.value - The ID of the selected city.
   * @param {string} formData.Address - The office's physical address.
   * @param {string} formData.Phone - The office's phone number.
   * @param {string} formData.Email - The office's email address.
   * @returns {Promise<void>} A promise that resolves after the form is submitted and handled.
   * @throws Will show an error toast if the API call fails.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.OFFICE_CREATE
      const payload = {
        _id: id ? id : undefined,
        name: formData.OfficeName,
        company: formData.CompanyName.value,
        typeOfOffice: formData.TypeOfOffice.value,
        city: formData.City.value,
        address: formData.Address,
        phone: formData.Phone,
        email: formData.Email,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Office "${formData.OfficeName}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/company-setup/office-list`)
      } else {
        addToast({
          title: `Office creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific state and sets the form values.
   *
   * @async
   * @function getOfficeDetails
   * @returns {Promise<void>} A promise that resolves when the state details are fetched and the form values are set.
   */
  const getOfficeDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.OFFICE_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: true,
        accessOf: ['officeEdit'],
        accessToken: 'TRqQbw5z6Svuh9CA',
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/company-setup/office-list`)
      } else if (response?.data?.value) {
        setValue(
          'CompanyName',
          {
            label: response?.data?.data?.company?.name,
            value: response?.data?.data?.company?._id,
          } || {}
        )
        setValue(
          'TypeOfOffice',
          {
            label: response?.data?.data?.typeOfOffice?.name,
            value: response?.data?.data?.typeOfOffice?._id,
          } || {}
        )
        setValue(
          'City',
          {
            label: response?.data?.data?.city?.name,
            value: response?.data?.data?.city?._id,
          } || {}
        )
        setValue('OfficeName', response?.data?.data?.name || '')
        setValue('Address', response?.data?.data?.address || '')
        setValue('Phone', response?.data?.data?.phone || '')
        setValue('Email', response?.data?.data?.email || '')
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/company-setup/office-list`)
  }

  /**
   * Fetches city options from the API based on the user input.
   * Sends a request to retrieve cities matching the input value.
   *
   * @async
   * @function fetchCityOptions
   * @param {string} [inputValue=''] - The user-typed value used to filter city options.
   * @returns {Promise<void>} A promise that resolves when the city options are fetched and set.
   */
  const fetchCityOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const apiUrl = apiConfig.COMPANY_CITY_LIST
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: 1,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        const newOptions = response?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setCityOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Handles the input change event for the city field.
   * Triggers the API call to fetch city options if the input value is longer than 2 characters.
   *
   * @function handleCityInputChange
   * @param {string} inputValue - The current input value from the city input field.
   */
  const handleCityInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchCityOptions(inputValue)
    }
  }

  /**
   * Handles the selection change event for the city field.
   * If no city is selected, it fetches the available city options and clears the city options state.
   *
   * @function handleCitySelectionChange
   * @param {Object|null} City - The selected city object or null if no city is selected.
   */
  const handleCitySelectionChange = (City) => {
    if (!City) {
      fetchCityOptions() // Call the function to perform API request
      setCityOptions([])
    }
  }

  useEffect(() => {
    if (id) {
      getOfficeDetails()
    }
    fetchOptions()
    fetchOfficeTypeOptions()
    fetchCityOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          {id ? 'Edit' : 'Add'} Office
        </p>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Company Name <span className="text-red-500">*</span>
            </label>
            <Controller
              name="CompanyName"
              control={control}
              rules={{ required: 'Company name is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={companyNameOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(CompanyName) => {
                    field.onChange(CompanyName)
                    handleSelectionChange(CompanyName) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                    }),
                  }}
                />
              )}
            />
            {errors.CompanyName && (
              <div className="error-message text-right text-red-500">
                {errors.CompanyName.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Type Of Office <span className="text-red-500">*</span>
            </label>
            <Controller
              name="TypeOfOffice"
              control={control}
              rules={{ required: 'Type of office is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={officeTypeOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleOfficeTypeInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(TypeOfOffice) => {
                    field.onChange(TypeOfOffice)
                    handleOfficeTypeSelectionChange(TypeOfOffice) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                    }),
                  }}
                />
              )}
            />
            {errors.TypeOfOffice && (
              <div className="error-message text-right text-red-500">
                {errors.TypeOfOffice.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Office Name"
              id="OfficeName"
              type="text"
              placeholder="Enter office name"
              registrationProps={register('OfficeName', {
                required: 'Office name is required',
              })}
              isFieldRequired={true}
              state={errors.OfficeName && 'error'}
            />
            <div className="error-message text-right">
              {errors.OfficeName?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">City</label>
            <Controller
              name="City"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={cityOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleCityInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(City) => {
                    field.onChange(City)
                    handleCitySelectionChange(City) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: 'rgba(218, 222, 236, 1)',
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      boxShadow: state.isFocused
                        ? `0 0 0 1px rgba(218, 222, 236, 1)`
                        : '',
                      '&:hover': {
                        borderColor: 'rgba(218, 222, 236, 1)',
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: '#999',
                    }),
                  }}
                />
              )}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Address"
              id="Address"
              type="text"
              placeholder="Enter address"
              registrationProps={register('Address', {})}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Phone"
              id="Phone"
              type="text"
              placeholder="Enter phone"
              registrationProps={register('Phone', {})}
            />
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Email"
              id="Email"
              type="text"
              placeholder="Enter email"
              registrationProps={register('Email', {})}
            />
          </div>

          {/* Button aligned to the right */}
          <div className="flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
              type="submit"
            >
              <span>{id ? 'Update' : 'Save'}</span>
            </button>

            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
              type="button"
              onClick={handleCancel}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default AddOffice
