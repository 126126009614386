import { projectColumnsDataOrders } from './variables/columnsDataOrders'
import ListProjectsTable from './components/ListProjectsTable'
import Card from 'components/card'
import { getAllProjectsData } from 'common/commonFunction'
import { useState, useEffect } from 'react'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'

const { TOAST } = CrawfordConstants

/**
 * Renders the Project List component with the fetched project data.
 *
 * @return {JSX.Element} The rendered Project List component
 */
const ProjectList = () => {
  const [allAppData, setApplicationData] = useState([])
  const { addToast } = useToaster()
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const itemsPerPage = 10 // You can change this to your desired number
  const [isProjectLoading, setProjectLoading] = useState(false)
  useEffect(() => {
    const organizationData = JSON.parse(
      localStorage.getItem('organizationData')
    )
    const isGitToken = organizationData?.github_token
    const fetchData = async () => {
      setProjectLoading(true)
      const response = await getAllProjectsData(
        currentPage === 0 ? 1 : currentPage
      )
      setProjectLoading(false)
      if (response?.data?.code === 200) {
        setApplicationData(response?.data?.data?.results)
        setTotalCount(response?.data?.data?.count)
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    }
    if (isGitToken) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <ListProjectsTable
          tableData={allAppData}
          columnsData={projectColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isProjectLoading={isProjectLoading}
          setProjectLoading={setProjectLoading}
        />
      </Card>
    </>
  )
}

export default ProjectList
