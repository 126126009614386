export const officeColumnsDataOrders = [
    {
      Header: 'S.No',
      accessor: 'index',
      disableSortBy: true,
    },
    {
      Header: 'Office Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Address',
      accessor: 'address',
      disableSortBy: true,
    },
    {
      Header: 'ACTIONS',
      accessor: 'OFFICE ACTIONS',
      disableSortBy: true,
    },
  ]
  