export const branchColumnsDataOrders = [
    {
      Header: 'S.No',
      accessor: 'index',
      disableSortBy: true,
    },
    {
      Header: 'Branch Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Branch Code',
      accessor: 'code',
      disableSortBy: true,
    },
    {
      Header: 'Short Name',
      accessor: 'shortName',
      disableSortBy: true,
    },
    {
      Header: 'ACTIONS',
      accessor: 'BRANCH ACTIONS',
      disableSortBy: true,
    },
  ]
  