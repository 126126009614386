import React from 'react'
import SegmentList from 'views/Customers/Segment'
// import CompanyAdd from 'views/CompanySetup/Company/components/AddCompany'
import TypeOfOfficeList from 'views/CompanySetup/TypeOfOffice'
// import OfficeTypeAdd from 'views/CompanySetup/TypeOfOffice/components/AddTypeOfOffice'
import OfficeList from 'views/CompanySetup/Office'
// import OfficeAdd from 'views/CompanySetup/Office/components/AddOffice'
import BranchList from 'views/CompanySetup/Branch'
// import BranchAdd from 'views/CompanySetup/Branch/components/AddBranch'
import { FaBuilding, FaUser } from 'react-icons/fa'

const customersRoutes = [
  {
    name: 'Segment',
    layout: '/afterLogin',
    path: '/customers/segment-list',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    component: <SegmentList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Segment', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Company',
    layout: '/afterLogin',
    path: '/customers/company-list',
    icon: <FaBuilding className="text-inherit h-6 w-4" />,
    component: <TypeOfOfficeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Company', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer',
    layout: '/afterLogin',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    path: '/customers/customer-list',
    component: <OfficeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Customer', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Officer',
    layout: '/afterLogin',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    path: '/customers/officer-list',
    component: <BranchList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Officer', link: '', breadCrumDisabled: true },
    ],
  },
]

export default customersRoutes
