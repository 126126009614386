import loginImg from 'assets/img/layers/crawford-logo.png'
import microsoftImg from 'assets/img/layers/microsoft-logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useEffect, useState, useCallback } from 'react'

const { TOAST, SIGNIN } = CrawfordConstants
const apiService = ApiCaller()

function SignInDefault() {
  const navigate = useNavigate()
  const location = useLocation() // Get the location object from React Router
  const queryParams = new URLSearchParams(location.search) // Parse the search string
  const base_url_sign_in = process.env.REACT_APP_API_BASE_URL
  console.log('base_url_sign_in', base_url_sign_in)
  console.log(
    'process.env.REACT_APP_API_BASE_URL',
    process.env.REACT_APP_API_BASE_URL
  )
  console.log('process.env.REACT_APP_API_BASE_URL', process.env)
  const handleContinueWithMicrosoft = () => {
    const currentHost = window.location.origin
    const redirectUrl = `${base_url_sign_in}api/user/loginOutlook?returnUrl=${currentHost}/sign-in`

    // Simulate a redirection (this can also be done via a redirect in the backend)
    window.location.href = redirectUrl
  }

  useEffect(() => {
    const token = queryParams.get('token')
    if (token) {
      // Store the access token
      localStorage.setItem('token', token)
      // Redirect to a different route after storing the token
      navigate('/') // Change this to the desired route
    } else {
      navigate('/sign-in') // Change this to the desired route
    }
  }, [navigate])

  return (
    <div className="h-screen bg-white md:flex">
      <div className="left-side-bottom-image relative flex h-full items-center justify-around overflow-hidden bg-gradient-to-b from-purple-lightViolet via-purple-hanPurple to-purple-darkHanPurple p-5 md:w-1/2">
        <div className="w-[400px]">
          <div className="items-left flex w-full max-w-full flex-col text-5xl font-bold text-white">
            PURI CRAWFORD
            <div class="mt-2 text-base">{SIGNIN.WELCOMETEXT}</div>
            <button className="mt-4 flex w-[100px] items-center justify-center gap-2 rounded-3xl bg-white p-1 hover:cursor-pointer dark:bg-navy-800">
              <p className="text-sm font-medium text-blue-500 text-navy-700">
                Read More
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="hidden h-full w-full items-center justify-center bg-white py-10 md:flex md:w-1/2">
        <div className="bg-white pb-2">
          <img
            src={loginImg}
            alt="Crawford Logo"
            className="h-40 w-full object-contain"
          />
          <div className="mb-18 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            <div className="mt-[1vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
              <button
                className="mb-4 flex w-full items-center justify-center gap-2 rounded-3xl border border-blue-500 bg-white p-2 hover:cursor-pointer dark:bg-navy-800 dark:text-white"
                onClick={handleContinueWithMicrosoft} // Add your onClick handler here
              >
                <p className="text-sm font-medium text-navy-700 dark:text-white">
                  Continue with
                </p>
                <div className="rounded-full text-xl">
                  <img
                    src={microsoftImg}
                    alt="Microsoft Logo"
                    className="h-4 w-full object-contain"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignInDefault
