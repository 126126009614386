import { companyColumnsDataOrders } from './variables/ColumnDataOrders'
import CompanyListTable from './components/List'
import Card from 'components/card'
import apiConfig from 'common/config/apiConfig'
import { useState, useEffect } from 'react'
import ApiCaller from 'common/services/apiServices'

/**
 * Renders the Company List component with the fetched Company data.
 *
 * @return {JSX.Element} The rendered Company List component
 */
const CompanyList = () => {
  const [allConmpanyData, setCompanyData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isCompanyLoading, setCompanyLoading] = useState(false)
  const apiService = ApiCaller()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCompanyLoading(true)
        const apiUrl = apiConfig.COMPANY_LIST
        const payload = {
          page: currentPage,
          keyword: searchValue,
        }
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.value) {
          setCompanyData(response?.data?.data?.results)
          setTotalCount(response?.data?.data?.total || 0)
        } else {
          setCompanyData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setCompanyLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setCompanyLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <CompanyListTable
          tableData={allConmpanyData}
          columnsData={companyColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isCompanyLoading={isCompanyLoading}
          setCompanyLoading={setCompanyLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default CompanyList
