import React, { useState, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Portal } from '@chakra-ui/portal'
import Navbar from 'components/navbar'
import Sidebar from 'components/sidebar'
// import Footer from 'components/footer/Footer'
import RoutesComponent from 'routes'
import AuthGuard from 'common/guard/authGuard'
import PageNotFound from 'views/others/404'
import 'assets/css/App.css'

/**
 * Renders the layout for the after login page.
 *
 * @param {object} props - The props object containing the component's properties.
 * @param {boolean} props.mini - Determines if the sidebar is in mini mode.
 * @param {function} props.setTheme - Function to set the theme.
 * @param {function} props.setMini - Function to set the mini property.
 * @return {JSX.Element} The rendered layout.
 */
const AfterLoginLayout = (props) => {
  const routes = RoutesComponent()
  const [routesData, setRoutesData] = useState([])
  const { ...rest } = props
  const location = useLocation()
  const [open, setOpen] = useState(true)
  const [sideBar, setSideBar] = useState(true)
  const [isExpand, setExpand] = useState(true)
  const [hovered, setHovered] = useState(false)
  const [currentRoute, setCurrentRoute] = useState('Dashboard')
  const [breadCrumbs, setBreadCrumbs] = useState('')
  useEffect(() => {
    if (routes) {
      setRoutesData(routes)
    }
  }, [routes])

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200
        ? setSideBar(false)
        : setSideBar(true) || window.innerWidth < 1200
        ? setSideBar(false)
        : setSideBar(true)
    )
  }, [])

  useEffect(() => {
    getActiveRoute(routesData)
    // eslint-disable-next-line
  }, [location.pathname, routesData])

  const getActiveRoute = (routes) => {
    let activeRoute = ''
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          let breadCrumb = routes[i].parentTitle
            ? `${routes[i].parentTitle} / ${routes[i].name}`
            : routes[i].name
          setCurrentRoute(routes[i].name)
          setBreadCrumbs(breadCrumb)
        } else if (
          (window.location.href.includes('country-') &&
            routes[i].name === 'Country') ||
          (window.location.href.includes('zone-') &&
            routes[i].name === 'Zone') ||
          (window.location.href.includes('state-') &&
            routes[i].name === 'State') ||
          (window.location.href.includes('district-') &&
            routes[i].name === 'District') ||
          (window.location.href.includes('city-') &&
            routes[i].name === 'City') ||
          (window.location.href.includes('company-') &&
            routes[i].name === 'Company') ||
          (window.location.href.includes('typeOfOffice-') &&
            routes[i].name === 'Type Of Office') ||
          (window.location.href.includes('office-') &&
            routes[i].name === 'Office') ||
          (window.location.href.includes('branch-') &&
            routes[i].name === 'Branch') ||
          (window.location.href.includes('segment-') &&
            routes[i].name === 'Segment') ||
          (window.location.href.includes('employee-') &&
            routes[i].name === 'Employee') ||
          (window.location.href.includes('function-') &&
            routes[i].name === 'Function') ||
          (window.location.href.includes('grade-') &&
            routes[i].name === 'Grade')
        ) {
          setCurrentRoute(routes[i].name)
          setBreadCrumbs(routes[i].name)
        }
      }
    }
    return activeRoute
  }
  /**
   * Returns the active navbar item based on the provided routes.
   *
   * @param {Array} routes - The array of route objects
   * @return {boolean} The active navbar item
   */
  const getActiveNavbar = (routes) => {
    let activeNavbar = false
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items)
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].secondary
        }
      }
    }
    return activeNavbar
  }
  useEffect(() => {
    sideBarClose()
    window.addEventListener('resize', sideBarClose)
    return () => {
      window.removeEventListener('resize', sideBarClose)
    }
  }, [])

  /**
   * A function that closes the sidebar based on the window width.
   *
   * @return {void} No return value
   */
  const sideBarClose = () => {
    if (window.innerWidth <= 1240) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/afterLogin') {
        return (
          <Route
            path={`${prop.path}`}
            element={<AuthGuard>{prop.component}</AuthGuard>}
            key={key}
            {...rest}
          />
        )
      }
      if (prop.collapse) {
        return getRoutes(prop.items)
      }
      return null
    })
  }
  return (
    <div className="flex h-full w-full bg-background-100 dark:bg-background-900">
      <Sidebar
        onOpenSideBar={() => setSideBar(!sideBar)}
        open={open}
        sideBar={sideBar}
        hovered={hovered}
        isExpand={isExpand}
        setHovered={setHovered}
        onExpand={() => setExpand(!isExpand)}
        mini={props.mini}
        onClose={() => sideBarClose()}
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full  font-dm dark:bg-background-900">
        {/* Main Content */}
        <main
          className={`bg-confixaBg mx-2.5 min-h-screen flex-none transition-all dark:bg-background-900  ${
            props.mini === false &&
            !isExpand &&
            location.pathname.includes('/observability')
              ? '!important xl:ml-[0px]'
              : props.mini === false && !isExpand
              ? 'xl:ml-[90px]'
              : props.mini === true && hovered === true
              ? 'xl:ml-[313px]'
              : sideBar && location.pathname.includes('/observability')
              ? '!important xl:ml-[0px]'
              : isExpand || location.pathname.includes('/observability')
              ? 'xl:ml-[313px]'
              : 'xl:ml-[313px]'
          }  ${
            location.pathname.includes('/observability') ? '' : 'md:p-6 md:pr-2'
          }`}
        >
          {/* Routes */}
          <div>
            <Portal>
              <Navbar
                onOpenSidenav={() => setOpen(!open)}
                brandText={currentRoute}
                breadCrumbs={breadCrumbs}
                secondary={getActiveNavbar(routes)}
                theme={props.theme}
                setTheme={props.setTheme}
                hovered={hovered}
                mini={props.mini}
                setMini={props.setMini}
                isExpand={isExpand}
                {...rest}
              />
            </Portal>
            <div className="mt-[100px]">
              <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<Navigate to="/" replace />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
            {/* <Footer /> */}
          </div>
        </main>
      </div>
    </div>
  )
}
export default AfterLoginLayout
