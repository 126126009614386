import React, { useState, useEffect } from 'react'
import MainProjectList from 'views/project/projectList'
import EmailInterface from 'views/Email'
import SignInToken from 'views/signIn/SignInDefault'
import {
  MdEmail,
  MdAssignment,
  MdAssignmentTurnedIn,
  MdShoppingCart,
} from 'react-icons/md'
import { RiShieldUserFill } from 'react-icons/ri'
import { BsBuildingsFill } from 'react-icons/bs'
import { HiUserGroup, HiTemplate } from 'react-icons/hi'
import { FaHome, FaUserClock, FaFileInvoice } from 'react-icons/fa'
import { IoShieldCheckmarkSharp, IoDocumentsSharp } from 'react-icons/io5'
import { FaMapLocationDot } from 'react-icons/fa6'
import locationRoutes from './views/Location/locationsRoutes'
import companyRoutes from './views/CompanySetup/companyRoutes'
import customersRoutes from 'views/Customers/customersRoutes'
import employeeRoutes from 'views/Employee/employeeRoutes'

const RoutesComponent = () => {
  const [routes, setRoutes] = useState([])
  useEffect(() => {
    setRoutes([
      // {
      //   name: 'SignInToken',
      //   path: '/sign-in/:id',
      //   layout: '/signIn',
      //   component: <SignInToken />,
      //   isComing: false,
      //   isVisible: true,
      // },
      {
        name: 'Home',
        path: '/',
        layout: '/afterLogin',
        icon: <FaHome className="text-inherit h-6 w-6" />,
        component: <EmailInterface />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Email',
        path: '/inbox',
        layout: '/afterLogin',
        icon: <MdEmail className="text-inherit h-6 w-6" />,
        component: <EmailInterface />,
        isComing: false,
        isVisible: true,
        breadcrum: [
          { label: 'Email', link: '' },
          { label: 'Inbox', link: '/inbox' },
        ],
      },
      {
        name: 'Role',
        path: '/role',
        layout: '/afterLogin',
        icon: <RiShieldUserFill className="text-inherit h-6 w-6" />,
        component: <MainProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Company Setup',
        path: '/company-setup',
        icon: <BsBuildingsFill className="text-inherit h-6 w-6" />,
        collapse: true,
        items: companyRoutes,
      },
      {
        name: 'Locations',
        path: '/locations',
        icon: <FaMapLocationDot className="text-inherit h-6 w-6" />,
        collapse: true,
        items: locationRoutes, // Inject location routes here
      },

      {
        name: 'Customers',
        path: '/customers',
        icon: <HiUserGroup className="text-inherit h-6 w-6" />,
        collapse: true,
        items: customersRoutes,
      },
      {
        name: 'Employee',
        path: '/employee',
        icon: <FaUserClock className="text-inherit h-6 w-6" />,
        collapse: true,
        items: employeeRoutes,
      },
      {
        name: 'Insurance',
        path: '/insurance',
        layout: '/afterLogin',
        icon: <IoShieldCheckmarkSharp className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Templates',
        path: '/templates',
        layout: '/afterLogin',
        icon: <HiTemplate className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Invoice',
        path: '/invoice',
        layout: '/afterLogin',
        icon: <FaFileInvoice className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Assignments',
        path: '/assignments',
        layout: '/afterLogin',
        icon: <MdAssignment className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Approvals',
        path: '/approvals',
        layout: '/afterLogin',
        icon: <MdAssignmentTurnedIn className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Logistics',
        path: '/logistics',
        layout: '/afterLogin',
        icon: <MdShoppingCart className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Reports',
        path: '/reports',
        layout: '/afterLogin',
        icon: <IoDocumentsSharp className="text-inherit h-6 w-6" />,
        // component: <CreateProjectList />,
        isComing: false,
        isVisible: true,
      },
    ])
  }, [])

  return routes
}

export default RoutesComponent
