export const countryColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Country Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Country Code',
    accessor: 'countryCode',
    disableSortBy: true,
  },
  {
    Header: 'ISD Code',
    accessor: 'ISDCodes',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'COUNTRY ACTIONS',
    disableSortBy: true,
  },
]
