import React from 'react'
import CompanyList from 'views/CompanySetup/Company'
import CompanyAdd from 'views/CompanySetup/Company/components/AddCompany'
import TypeOfOfficeList from 'views/CompanySetup/TypeOfOffice'
import OfficeTypeAdd from 'views/CompanySetup/TypeOfOffice/components/AddTypeOfOffice'
import OfficeList from 'views/CompanySetup/Office'
import OfficeAdd from 'views/CompanySetup/Office/components/AddOffice'
import BranchList from 'views/CompanySetup/Branch'
import BranchAdd from 'views/CompanySetup/Branch/components/AddBranch'
import { FaBuilding } from 'react-icons/fa'
import { IoEarthSharp } from 'react-icons/io5'
import { FaLocationArrow, FaLocationDot } from 'react-icons/fa6'
import { FaLink } from 'react-icons/fa'

const CompanyRoutes = [
  {
    name: 'Company',
    layout: '/afterLogin',
    path: '/company-setup/company-list',
    icon: <FaBuilding className="text-inherit h-6 w-6" />,
    component: <CompanyList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Company', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Company',
    layout: '/afterLogin',
    path: '/company-setup/company-create',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <CompanyAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Company', link: '/company-setup/company-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Company',
    layout: '/afterLogin',
    path: '/company-setup/company-edit/:id',
    icon: <IoEarthSharp className="text-inherit h-6 w-6" />,
    component: <CompanyAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Company', link: '/company-setup/company-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Type Of Office',
    layout: '/afterLogin',
    path: '/company-setup/typeOfOffice-list',
    icon: <FaBuilding className="text-inherit h-6 w-6" />,
    component: <TypeOfOfficeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Type Of Office', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Type Of Office',
    layout: '/afterLogin',
    path: '/company-setup/typeOfOffice-create',
    icon: <FaLocationArrow className="text-inherit h-6 w-6" />,
    component: <OfficeTypeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Type Of Office', link: '/company-setup/typeOfOffice-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Type Of Office',
    layout: '/afterLogin',
    path: '/company-setup/typeOfOffice-edit/:id',
    icon: <FaLocationArrow className="text-inherit h-6 w-6" />,
    component: <OfficeTypeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Type Of Office', link: '/company-setup/typeOfOffice-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Office',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/company-setup/office-list',
    component: <OfficeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Office', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Office',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/company-setup/office-create',
    component: <OfficeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Office', link: '/company-setup/office-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Office',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/company-setup/office-edit/:id',
    component: <OfficeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Office', link: '/company-setup/office-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Branch',
    layout: '/afterLogin',
    icon: <FaLink className="text-inherit h-6 w-6" />,
    path: '/company-setup/branch-list',
    component: <BranchList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Branch', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Branch',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/company-setup/branch-create',
    component: <BranchAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Branch', link: '/company-setup/branch-list' },
      { label: 'Add', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'District',
    layout: '/afterLogin',
    icon: <FaLocationDot className="text-inherit h-6 w-6" />,
    path: '/company-setup/branch-edit/:id',
    component: <BranchAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Company Setup',
    breadcrum: [
      { label: 'Company Setup', link: '', breadCrumDisabled: true },
      { label: 'Branch', link: '/company-setup/branch-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
]

export default CompanyRoutes
