import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'

const EmailView = ({ email }) => {
  const [assignmentBtn, setAssignmentBtn] = useState(false)
  const [attachments, setAttachments] = useState([])
  const navigate = useNavigate()

  const createAssignment = () => {
    // Logic for creating an assignment with email date
  }

  const fetchAttachments = async (f) => {
    try {
      // Simulate API call to fetch attachments
      var a = {
        // attachmentId: f.body.attachmentId,
        // fileName: f.filename,
        // messageId: $stateParams.id,
      }
      //   const win = window.open(
      //     adminurl +
      //       'user/getAttachment?accessToken=' +
      //       f +
      //       '&fileName=' +
      //       f.filename +
      //       '&attachmentId=' +
      //       f.body.attachmentId +
      //       '&messageId=' +
      //       //   $stateParams.id,
      //       '_blank'
      //   )
    } catch (error) {
      console.error('Error fetching attachments:', error)
    }
  }

  const openAttachment = (attachment) => {
    // Logic to open/download the attachment
    const fileBlob = new Blob([attachment.contentBytes], {
      type: attachment.contentType,
    })
    const url = window.URL.createObjectURL(fileBlob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', attachment.name)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  return (
    <div className={`${assignmentBtn ? 'hidden' : 'block'}`}>
      <div className="flex">
        <div className="w-full">
          <div className="mb-4">
            <h2 className="text-2xl font-bold">{email.subject}</h2>
          </div>
        </div>
      </div>

      <div className="rounded-lg bg-white p-6 shadow">
        <div className="flex flex-col space-y-4">
          <div className={`${assignmentBtn ? 'hidden' : 'block'}`}>
            <div className="space-y-6">
              <div className="mb-4 flex items-center justify-between border-b pb-4">
                <div>
                  <h3 className="text-lg font-semibold">
                    {email.sender.emailAddress.name}
                  </h3>
                  <p className="text-sm text-gray-600">
                    To: {email.toRecipients[0].emailAddress.name} (
                    {email.toRecipients[0].emailAddress.address})
                  </p>
                </div>

                <div className="flex space-x-2">
                  <button
                    className="btn btn-default rounded-md bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
                    data-tip="Create Assignment with email date"
                    onClick={createAssignment}
                  >
                    Create Assignment
                  </button>
                  <button
                    className="btn btn-default rounded-md bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
                    data-tip="Back"
                    onClick={() => navigate('/email-inbox')}
                  >
                    <i className="fa fa-arrow-left"></i>
                  </button>
                  {/* <button
                    className="btn btn-default rounded-md bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
                    data-tip="Reply to All"
                  >
                    <i className="fa fa-reply-all"></i>
                  </button> */}
                  {/* <button
                    className="btn btn-default rounded-md bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
                    data-tip="Forward"
                  >
                    <i className="fa fa-share"></i>
                  </button> */}
                  {/* <ReactTooltip place="top" type="dark" effect="solid" /> */}
                </div>
              </div>

              <div className="rounded-lg bg-gray-50 p-4">
                <p>
                  <b>Date</b>: {new Date(email.sentDateTime).toLocaleString()}{' '}
                  <br />
                  <b>Subject</b>: {email.subject} <br />
                  <b>From</b>: {email.sender.emailAddress.name} (
                  {email.sender.emailAddress.address}) <br />
                  <b>To</b>: {email.toRecipients[0].emailAddress.name} (
                  {email.toRecipients[0].emailAddress.address}) <br />
                  {email.ccRecipients.length > 0 && (
                    <>
                      <b>Cc</b>:{' '}
                      {email.ccRecipients.map((cc, index) => (
                        <span key={index}>
                          {cc.emailAddress.name} ({cc.emailAddress.address}){' '}
                        </span>
                      ))}{' '}
                      <br />
                    </>
                  )}
                </p>
                <div dangerouslySetInnerHTML={{ __html: email.body.content }} />
              </div>

              {/* Fetch and Display Attachments */}
              {email.hasAttachments && (
                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={fetchAttachments}
                  >
                    Load Attachments
                  </button>

                  <div className="mt-4 grid grid-cols-2 gap-4 md:grid-cols-4">
                    {attachments.map((attachment) => (
                      <div
                        key={attachment.id}
                        className="cursor-pointer rounded-lg border p-4 shadow-sm hover:shadow-md"
                        onClick={() => openAttachment(attachment)}
                      >
                        <i className="ln-document text-4xl"></i>
                        <p className="mt-2 text-sm font-semibold">
                          {attachment.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {assignmentBtn && (
            <div className="text-center">
              <h4 className="text-lg font-semibold">Relogin Required</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EmailView
