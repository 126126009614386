export const companyColumnsDataOrders = [
    {
      Header: 'S.No',
      accessor: 'index',
      disableSortBy: true,
    },
    {
      Header: 'Company Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Short name',
      accessor: 'shortName',
      disableSortBy: true,
    },
    {
      Header: 'City',
      disableSortBy: true,
    },
    {
      Header: 'ACTIONS',
      accessor: 'COMPANY ACTIONS',
      disableSortBy: true,
    },
  ]
  