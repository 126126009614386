/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { HiX } from 'react-icons/hi'
import Links from './components/Links'
import { RxHamburgerMenu } from 'react-icons/rx'
import crawfordLogo from 'assets/img/others/crawfordLogo.png'
import crawfordInnerLogo from 'assets/img/layers/ Crawford-inner-logo.png'
import { useLocation } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import { getOneBillingData } from 'common/commonFunction'
import moment from 'moment'

import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar'
import { Scrollbars } from 'react-custom-scrollbars-2'
import RoutesComponent from 'routes'
import Card from 'components/card'
import CrawfordConstants from 'common/config/crawfordConstants'
const { Billing, BUTTON_TEXT, CRAWFORD } = CrawfordConstants

/**
 * SidebarHorizon component function.
 *
 * @param {Object} props - an object containing the component's props
 * @return {JSX.Element} The SidebarHorizon component
 */
function SidebarHorizon(props) {
  const routes = RoutesComponent()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [isObservabilityPage, setIsObservabilityPage] = useState('')
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1240)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getZIndex = () => {
    if (isObservabilityPage && sideBar) {
      return 'z-index-responsive'
    } else if (isObservabilityPage) {
      return 'z-50'
    } else {
      return ''
    }
  }
  const {
    open,
    onClose,
    variant,
    mini,
    hovered,
    setHovered,
    onExpand,
    isExpand,
    sideBar,
    onOpenSideBar,
  } = props
  const currentYear = new Date().getFullYear()
  return (
    <>
      <div>
        {location.pathname === '/observability' && (
          <div>
            <span
              className={`sidebar-toggle-icon ${isExpand && !sideBar
                  ? 'expanded'
                  : sideBar
                    ? 'sidebar-open'
                    : ''
                }`}
              onClick={() => {
                onOpenSideBar()
              }}
            >
              {!sideBar && isExpand ? (
                <FaArrowLeft onClick={() => onOpenSideBar()} />
              ) : isExpand ? (
                <FaArrowRight onClick={() => onOpenSideBar()} />
              ) : (
                <FaArrowRight onClick={() => onOpenSideBar()} />
              )}
            </span>
          </div>
        )}
      </div>
      <div
        className={`sm:none ${isExpand ? 'w-[285px]' : 'w-[86px]'} ${mini === false
            ? 'w-[285px]'
            : mini === true && hovered === true
              ? 'w-[285px]'
              : 'w-[285px] xl:!w-[120px]'
          } 
        duration-175 linear fixed min-h-full transition-all ${variant === 'auth' ? 'xl:hidden' : 'xl:block '
          } ${sideBar && location.pathname === '/observability'
            ? '!z-50 -translate-x-[115%]'
            : ''
          } ${open ? '' : '-translate-x-[115%]'} ${isSmallScreen ? 'z-index-responsive' : ''
          }${getZIndex()}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Card
          extra={`w-full h-[100vh]`}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={
              mini === false
                ? renderView
                : mini === true && hovered === true
                  ? renderView
                  : renderViewMini
            }
          >
            <div className="pb-[1%]">
              <div className="sticky top-0 z-[151] cursor-pointer bg-white dark:bg-darkBackGround">
                <span
                  className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                  onClick={onClose}
                >
                  <HiX />
                </span>
                <span
                  className="absolute right-4 top-4 block hidden cursor-pointer xl:block"
                  onClick={onExpand}
                >
                  <RxHamburgerMenu className="transition hover:scale-110 hover:text-brand-500 dark:hover:text-white" />
                </span>
                <div className={`ml-[14px] mt-[8px] flex items-center `}>
                  {isExpand ? (
                    <div
                      className={`ml-4 font-poppins text-[25px] font-medium capitalize tracking-normal text-blueSecondary dark:text-white ${mini === false
                          ? 'block'
                          : mini === true && hovered === true
                            ? 'block'
                            : 'hidden'
                        }`}
                    >
                      <Link to="/">
                        <img src={crawfordInnerLogo} alt="" />
                      </Link>
                    </div>
                  ) : (
                    <Link to="/">
                      <img src={crawfordLogo} className="mt-2 w-[34px]" alt="" />
                    </Link>
                  )}
                </div>
                <div className="mb-2 mt-2 h-px bg-gray-200 dark:bg-white/10" />
              </div>

              {/* Nav item */}
              <ul>
                <Links
                  mini={mini}
                  hovered={hovered}
                  routes={routes}
                  isExpand={isExpand}
                />
              </ul>
            </div>
          </Scrollbars>
        </Card>
      </div>
    </>
  )
}

export default SidebarHorizon
