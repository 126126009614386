const fixaConstants = {
  TOAST: {
    MESSAGES: {
      ERROR: {
        fetchBranchError: 'Unable to fetch branches',
        fetchRepoError: 'Unable to fetch Repositories',
        cwsError: 'Web Service File already exist',
      },
      SUCCESS: {
        cwsSuccess: 'Web Service Created Successfully',
        addedIssue: 'issue added Successfully',
      },
    },
  },
  LABEL: {
    UPDATE_LABEL: 'Update',
    ADD_LABEL: 'Add',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    LABELS: 'Labels',
    NEW_LABEL: 'New Label',
    LABEL_NAME: 'Label Name',
    STATUS: 'Status',
    TOTAL_DUR: 'Total duration',
    ARTIFACTS: 'Artifacts',
    CREDENTIALS_TYPE: 'Credentials Type',
    NAME: 'Name',
    PROJECT_ID: 'Project ID',
  },
  CRAWFORD: {
    CRAWFORD_HEADER: 'Crawford',
  },
  BUTTON_TEXT: {
    EDIT: 'Edit',
    VERIFY: 'Verify',
    RENEW: ' Renew',
    CONNECT: 'Connect',
    UPDATE: 'Update',
    DOWNLOAD: 'Download',
    REPOSITORY: 'Repository',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    NEXT: 'Next',
    GITHUB: 'Github',
    PUBLIC: 'Public',
    PRIVATE: 'Private',
    BACK: 'Back',
    ADD: 'Add',
    SAVE_CHNG: 'Save Changes',
    UPDATES: 'Updates',
    NEW_LABEL: 'New Label',
    SAVE_CHANGES: 'Save Changes',
    ACTIVE: 'Active',
    PREVIOUS: 'Previous',
    COMING_SOON: 'Coming Soon',
  },
  SIGNIN: {
    WELCOMETEXT: `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`,
  },

  HEY: 'Hey,',
  NO_DATA_AVAIL: 'No Data Available',
  TABS: {
    DETAILS: 'Details',
  },
  TABLE_HEADER: {
    SR_NO: 'S.NO',
    ISSUES: 'ISSUES',
    AUTHOR: 'AUTHOR',
    PR_STATUS: 'PR STATUS',
    ACTION: 'ACTION',
    BRANCH_NAME: 'BRANCH NAME',
    KUBERNETES_CLUSTER: 'KUBERNETES CLUSTER',
    TOP_LEVEL_DOMAIN: 'TOP-LEVEL DOMAIN',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
  },
  NO_DATA: 'No Data Available',
}

export default fixaConstants
