import { stateColumnsDataOrders } from './variables/ColumnsDataOrders'
import StateListTable from './components/List'
import Card from 'components/card'
import { useState, useEffect } from 'react'
import { getState } from 'common/commonFunction'
/**
 * Renders the Country List component with the fetched country data.
 *
 * @return {JSX.Element} The rendered Country List component
 */
const StateList = () => {
  const [allStateData, setStateData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const itemsPerPage = 10 // You can change this to your desired number
  const [isStateLoading, setStateLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStateLoading(true)
        const payload = {
          page: currentPage,
          keyword: searchValue,
        }
        const getStateList = await getState(payload)
        if (getStateList?.data?.value) {
          setStateData(getStateList?.data?.data?.results)
          setTotalCount(getStateList?.data?.data?.total || 0)
        } else {
          setStateData([])
          setTotalCount(0)
        }
      } catch (error) {
        console.error('Error during API call:', error)
      } finally {
        setStateLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue]) // Fetch data whenever currentPage changes

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const setGlobalFilter = (value) => {
    try {
      setCurrentPage(1)
      setSearchValue(value)
    } catch (err) {
      console.log('err', err)
    } finally {
      setStateLoading(false)
    }
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <StateListTable
          tableData={allStateData}
          columnsData={stateColumnsDataOrders}
          totalCount={totalCount}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          isStateLoading={isStateLoading}
          setStateLoading={setStateLoading}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    </>
  )
}

export default StateList
