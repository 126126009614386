export const stateColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'State',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Zone',
    disableSortBy: true,
  },
  {
    Header: 'Country',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'COUNTRY ACTIONS',
    disableSortBy: true,
  },
]
